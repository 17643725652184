<template>
  <v-card class="box-shadow" outlined>
    <v-card-text class="pa-7 secondary--text">
      <div class="font-weight-bold">Extra Assets & Links</div>
      <v-divider class="my-3"></v-divider>

      <v-sheet max-width="650">
        <p v-if="description" v-html="$options.filters.linkify(description)" class="text-pre-wrap"></p>
      </v-sheet>

      <div class="d-flex flex-wrap gap-10">
        <template v-if="profile && profile.extra_assets && profile.extra_assets.length" >
          <v-card 
            v-for="(asset, i) in profile.extra_assets"
            :key="i"
            class="rounded-lg" 
            max-width="150" 
            width="100%" 
            height="150" 
            outlined
          >
            <v-menu offset-y>
              <template #activator="{ on }">
                <v-btn
                  class="rounded-lg rounded-tr-0 rounded-bl-0"
                  :style="{ right: '-1px', bottom: '-1px' }"
                  color="grey lighten-3"
                  v-on="on"
                  width="20"
                  depressed
                  absolute
                  outlined
                  small
                >
                  <v-hover>
                    <template v-slot="{ hover }">
                      <v-icon :color="hover ? 'primary' : 'grey lighten-2'" large>mdi-dots-horizontal</v-icon>
                    </template>
                  </v-hover>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="$downloadFile(asset.folder, asset.file)">
                  <v-list-item-icon class="mr-1">
                    <v-icon small>mdi-download</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Download</v-list-item-title>
                </v-list-item>
                <template v-if="!readonly">
                  <v-divider></v-divider>
                  <v-list-item @click="confirmDelete(asset)">
                    <v-list-item-icon class="mr-1">
                      <v-icon color="error" small>mdi-delete-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="error--text">Delete</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>

            <v-card-text class="d-flex flex-column fill-height justify-center align-center">
              <v-icon :color="$fileIcon(asset.type, true)">
                {{ $fileIcon(asset.type) }}
              </v-icon>
              <div :style="{ maxWidth: '90%' }" class="font-weight-bold secondary--text caption text-center d-inline-block text-truncate">{{ asset.file | fileName }}</div>
              <div class="font-weight-bold grey--text text--lighten-2 caption">[{{ asset.size | fileSize }}]</div>
              <div class="grey--text text--lighten-2" :style="{ fontSize: '9px' }">Last Updated {{ moment(asset.date.toDate()).format('MM.DD.YYYY') }}</div>
            </v-card-text>
          </v-card>
        </template>

        <v-card 
          v-if="!readonly"
          @click="showUploadDialog()" 
          class="rounded-lg d-flex align-center justify-center" 
          max-width="150" 
          width="100%" 
          height="150" 
          outlined 
          hover
        >
          <v-icon color="info" class="mr-1" small>mdi-upload</v-icon>
          <span class="caption">Upload file/s</span>
        </v-card>
      </div>

      <v-sheet class="mt-5" max-width="600">
        <label class="form-label">Video/Media Link</label>

        <v-form ref="linkForm">
          <v-fade-transition group>
            <div v-for="(link, i) in profile.links" :key="i">
              <v-text-field
                v-model="link.url"
                :rules="[rules.url]"
                @change="validateLinks()"
                :readonly="readonly"
                outlined
                dense
              >
                <template #append>
                  <v-btn v-if="i && !readonly" @click="removeLink(i)" icon x-small>
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </div>
          </v-fade-transition>
        </v-form>

        <v-btn
          v-if="!readonly"
          @click="$store.commit('brand_profile/addLink', profile)"
          class="primary--text"
          color="white"
          small
        >Add Another Link</v-btn>
      </v-sheet>
    </v-card-text>

    <!-- UPLOAD DIALOG -->
    <v-dialog
      v-model="dialog"
      :max-width="$dialog.content"
    >
      <close-btn @click="closeUploadDialog()" overflow />
      <v-card>
        <v-card-title> 
          <h5>Upload Files</h5>
        </v-card-title>
        <v-card-text>
          <upload-progress class="mb-3" />
          
          <v-card v-if="files.length" class="grey--text caption mt-1 mb-5 box-shadow" outlined>
            <v-simple-table dense>
              <template #default>
                <v-fade-transition tag="tbody" group>
                  <tr v-for="(file, i) in files" :key="i">
                    <td>{{ file.name }}</td>
                    <td class="caption">{{ file.size | fileSize }}</td>
                    <td class="text-right">
                      <v-btn @click="removeFile(file)" icon small>
                        <v-icon color="error" small>mdi-close</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </v-fade-transition>
              </template>
            </v-simple-table>
          </v-card>

          <v-form ref="form" @submit.prevent="validateForm()">
            <v-file-input
              v-model="file"
              :rules="[rules.required]"
              :disabled="status.uploading"
              @change="selected()"
              prepend-icon=""
              hide-details
              multiple
              outlined
              dense
            ></v-file-input>
          </v-form>

          <v-btn 
            v-if="files && files.length"
            @click="validateForm()"
            :loading="status.uploading"
            color="accent gradient" 
            class="mt-5"
            block
          >Upload</v-btn>
        </v-card-text>
      </v-card> 
    </v-dialog>

    <!-- CONFIRM DELETE -->
    <confirm-delete
      :show="showDeleteDialog"
      :deleting="$store.state.file_upload.status.deleting"
      :message="deleteMessage"
      @cancel="showDeleteDialog = false"
      @confirmed="deleteConfirmed()"
    />
  </v-card>
</template>

<script>
import rules from '@/rules'
import moment from 'moment'
import { uniqBy } from 'lodash'
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    profile: Object,
    readonly: Boolean,
    description: String,
  },
  
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      moment,
      file: [],
      files: [],
      dialog: false,
      toDelete: null,
      showDeleteDialog: false,
      deleteMessage: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.brand_profile.status,
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('brand_profile', [
      'uploadAssets',
      'saveLinks'
    ]),

    showUploadDialog() {
      this.dialog = true
    },

    closeUploadDialog() {
      this.dialog = false
      this.files = []
      this.$refs.form.resetValidation()
    },

    selected() {
      if (this.file.length) {
        this.files = uniqBy(this.files.concat(this.file), (f) => f.name)
      }
      
      this.file = []
    },

    removeFile(file) {
      let f = this.files.find(f => f == file)
      this.files.splice(this.files.indexOf(f), 1)
    },

    validateForm() {
      if (this.files.length) {
        Promise.resolve(this.uploadAssets({
          profile: this.profile,
          files: this.files
        }))
        .then(() => {
          this.closeUploadDialog()
        })
      }
      else {
        this.$store.dispatch('showError', 'Please select at least one file')
      }
    },

    confirmDelete(asset) {
      this.deleteMessage = `Delete file ${ this.$options.filters.fileName(asset.file) }?`
      this.showDeleteDialog = true
      this.toDelete = asset
    },

    deleteConfirmed() {
      var asset = this.toDelete
      
      Promise.resolve(this.$store.dispatch('file_upload/deleteFile', { folder: 'brand_profiles', file: asset.file }))
      .then(() => {
        let extra_assets = this.profile.extra_assets
        let a = extra_assets.find(a => a == asset)
        extra_assets.splice(extra_assets.indexOf(a), 1)

        this.profile.ref
        .update({ extra_assets })
        .then(() => {
          this.showDeleteDialog = false
          this.$store.commit('brand_profile/updateProfileData', { profile: this.profile, extra_assets })
        })
        .catch(error => {
          console.log(error.message)
        })
      })
    },

    validateLinks() {
      if (this.$refs.linkForm.validate()) {
        this.saveLinks(this.profile)
      }
    },

    removeLink(i) {
      this.$store.commit('brand_profile/removeLink', { profile: this.profile, index: i })

      setTimeout(() => {
        this.validateLinks()
      }, 1000)
    }
  }
}
</script>