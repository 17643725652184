<template>
  <v-card v-if="profile" class="box-shadow" outlined>
    <v-card-title class="font-weight-bold subtitle-1 primary--text">
      Members
    </v-card-title>
    <v-card-text>
      <v-card v-if="profile.members && profile.members.length" class="mb-3" outlined>
        <v-list dense>
          <v-list-item v-for="member in profile.members" :key="member">
            <v-list-item-avatar class="overflow-visible" rounded>
              <UserPhoto 
                photoSize="thumb"
                :id="member"
                :size="40"
                rounded 
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $store.getters['users/user'](member).fullName }}</v-list-item-title>
              <v-list-item-subtitle>{{ $store.getters['users/user'](member).email }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" @click="confirmRemove(member)" color="error" icon small>
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Remove member</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
      
      <v-card v-if="invited.length" class="mb-3" outlined>
        <div class="font-weight-bold pl-4 pt-3 pr-4">Pending Invites</div>
        <v-simple-table dense>
          <tbody>
            <tr v-for="invite in invited" :key="invite.id">
              <td>{{ invite.email }} <span class="grey--text text--lighten-1 caption">{{ invite.created | fromNowComplete }}</span></td>
              <td class="text-right">
                <v-btn 
                  @click="cancelInvite(invite)"
                  :loading="cancelling.includes(invite.id)"
                  x-small
                >
                  Cancel
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
      
      <v-form ref="inviteForm">
        <v-textarea 
          placeholder="You can type multiple email addresses separated by comma" 
          :rules="[rules.required]"
          v-model="emails" 
          type="email" 
          outlined
          rows="3"
          dense 
        ></v-textarea>
        <v-btn 
          @click="sendInvites()" 
          :loading="inviting"
          color="primary" 
          small
        >Invite</v-btn>
      </v-form>
    </v-card-text>

    <!-- CONFIRM DELETE -->
    <ConfirmDelete 
      :show="deleteDialog"
      :deleting="removing"
      @confirmed="removeMember()"
      @cancel="closeDeleteDialog()"
      :message="toDelete ? `Remove ${$store.getters['users/user'](toDelete).fullName}?` : 'none'"
    />
  </v-card>
</template>

<script>
import rules from '@/rules'
import firebase from 'firebase'
import { mapState } from 'vuex'
import db from '@/firebase/init'

export default {
  props: {
    profile: Object,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      invited: [],
      emails: null,
      toDelete: null,
      cancelling: [],
      inviting: false,
      removing: false,
      deleteDialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'profile': {
      handler(profile) {
        if (profile && profile.id) {
          this.getInvites()
        }

        if (profile && profile.members && profile.members.length) {
          for (var member of profile.members) {
            this.$store.dispatch('users/getUser', member)
          }
        }
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    // SEND INVITES
    async sendInvites() {
      if (this.$refs.inviteForm.validate()) {
        var emails = this.emails.split(',')
        let validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        
        emails = emails.filter(e => {
          let email = e.trim()
          return email.match(validEmail)
        })

        emails = emails.map(email => email.trim().toLowerCase())
        
        var promises = []

        if (emails.length) {
          this.inviting = true
          
          for (var email of emails) {
            let data = {
              email,
              profile: this.profile.id,
              created: firebase.firestore.Timestamp.now(),
            }

            let task = await db.collection('member_invites')
            .add(data)
            .then(async (docRef) => {
              data.id = docRef.id
              data.ref = docRef
              this.invited.push(data)
              
              let emailData = {
                to: email,
                subject: 'Company Profile Member Invite',
                message: `
                  Hi rockstar!,<br/>
                  <br/>
                  You have been invited by ${this.user.fullName} as a collaborator on their company profile <strong>${this.profile.name}</strong> .<br/><br/>
                  This allows you to view and update company information as well as submit support requests on behalf of this company.<br/><br/>
                  Click <a href="${window.location.origin}">here</a> and login to Vivacity app or create a new account.<br/><br />
                  Then check your notifications to accept or reject the invitation.
                `
              }

              this.$store.dispatch('email/sendMail', emailData)
            })
            
            promises.push(task)
          }

          Promise.all(promises)
          .then(() => {
            this.inviting = false
            this.$refs.inviteForm.reset()
            this.$refs.inviteForm.resetValidation()
          })
        }
      }
    },

    /*------------------------------------------------------------------------------
     * GET INVITES
     *----------------------------------------------------------------------------*/
    getInvites() {
      db.collection('member_invites')
      .where('profile', '==', this.profile.id)
      .get()
      .then((snapshot) => {
        if (snapshot.size) {
          snapshot.forEach(doc => {
            let data = doc.data()
            data.id = doc.id
            data.ref = doc.ref
            
            if (!this.invited.find(i => i.id == data.id)) {
              this.invited.push(data)
            }
          })
        }
      })
      .catch(error => {
        console.log(error.message)
      })
    },

    /*------------------------------------------------------------------------------
     * CONFIRM REMOVE MEMBER
     *----------------------------------------------------------------------------*/
    confirmRemove(id) {
      this.toDelete = id
      this.deleteDialog = true
    },

    /*------------------------------------------------------------------------------
     * CANCEL REMOVE
     *----------------------------------------------------------------------------*/
    closeDeleteDialog() {
      this.toDelete = null
      this.deleteDialog = false
    },

    /*------------------------------------------------------------------------------
     * REMOVE MEMBER 
     *----------------------------------------------------------------------------*/
    removeMember() {
      this.removing = true
      var members = this.profile.members
      members.splice(members.indexOf(this.toDelete), 1)

      this.profile.ref
      .update({ members })
      .then(() => {
        this.$store.dispatch('showSuccess', 'Member removed')
      })
      .catch(error => {
        this.$store.dispatch('showError', error.message)
      })
      .finally(() => {
        this.removing = false
        this.closeDeleteDialog()
      })
    },

    /*------------------------------------------------------------------------------
     * CANCEL INVITE
     *----------------------------------------------------------------------------*/
    cancelInvite(invite) {
      this.cancelling.push(invite.id)

      invite.ref.delete()
      .then(() => {
        this.invited.splice(this.invited.indexOf(invite), 1)
      })
      .catch(error => {
        console.log(error.message)
      })
      .finally(() => {
        this.cancelling.splice(this.cancelling.indexOf(invite.id), 1)
      })
    }
  }
}
</script>