<template>
  <v-card class="box-shadow" outlined>
    <v-card-text class="pa-7 secondary--text">
      <div class="font-weight-bold">Your Logo</div>
      <v-divider class="my-3"></v-divider>

      <v-sheet max-width="650">
        <p v-if="description" v-html="$options.filters.linkify(description)" class="text-pre-wrap"></p>
      </v-sheet>

      <div class="d-flex flex-wrap gap-10">
        <v-card 
          v-if="profile && profile.logo" 
          class="rounded-lg" 
          max-width="150" 
          width="100%" 
          height="150" 
          outlined
        >
          <v-menu offset-y>
            <template #activator="{ on }">
              <v-btn
                class="rounded-lg rounded-tr-0 rounded-bl-0"
                :style="{ right: '-1px', bottom: '-1px' }"
                color="grey lighten-3"
                v-on="on"
                width="20"
                depressed
                absolute
                outlined
                small
              >
                <v-hover>
                  <template v-slot="{ hover }">
                    <v-icon :color="hover ? 'primary' : 'grey lighten-2'" large>mdi-dots-horizontal</v-icon>
                  </template>
                </v-hover>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="$downloadFile(profile.logo.folder, profile.logo.file)">
                <v-list-item-icon class="mr-1">
                  <v-icon small>mdi-download</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Download</v-list-item-title>
              </v-list-item>
              <template v-if="!readonly">
                <v-divider></v-divider>
                <v-list-item @click="confirmDelete()">
                  <v-list-item-icon class="mr-1">
                    <v-icon color="error" small>mdi-delete-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="error--text">Delete</v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>

          <v-card-text class="d-flex flex-column fill-height justify-center align-center">
            <v-icon color="secondary">mdi-image-outline</v-icon>
            <div :style="{ maxWidth: '90%' }" class="font-weight-bold secondary--text caption text-center d-block text-truncate">{{ profile.logo.file | fileName }}</div>
            <div class="font-weight-bold grey--text text--lighten-2 caption">[{{ profile.logo.size | fileSize }}]</div>
            <div class="grey--text text--lighten-2" :style="{ fontSize: '9px' }">Last Updated {{ moment(profile.logo.date.toDate()).format('MM.DD.YYYY') }}</div>
          </v-card-text>
        </v-card>

        <v-card 
          v-else-if="!profile.logo && !readonly"
          @click="showUploadDialog()" 
          class="rounded-lg d-flex align-center justify-center transition" 
          max-width="150" 
          width="100%" 
          height="150" 
          outlined 
          hover
        >
          <v-icon color="info" class="mr-1" small>mdi-upload</v-icon>
          <span class="caption">Upload a file</span>
        </v-card>
      </div>
    </v-card-text>

    <!-- UPLOAD DIALOG -->
    <v-dialog
      v-model="dialog"
      :max-width="$dialog.content"
    >
      <close-btn @click="closeUploadDialog()" overflow/>

      <v-card>
        <v-card-title>
          <h5>Upload File</h5>
        </v-card-title>
        <v-card-text>
          <upload-progress class="mb-3" />
          
          <v-form ref="form" @submit.prevent="validateForm()">
            <v-file-input
              v-model="file"
              accept="application/postscript, image/*"
              :rules="[rules.required]"
              :disabled="status.uploading"
              prepend-icon=""
              hide-details
              outlined
              dense
            ></v-file-input>
          </v-form>

          <div v-if="file" class="grey--text caption mt-1">
            File size: {{ file.size | fileSize }}
          </div>

          <v-btn 
            v-if="file"
            @click="validateForm()"
            :loading="status.uploading"
            color="accent gradient" 
            class="mt-5"
            block
          >Upload</v-btn>
        </v-card-text>
      </v-card> 
    </v-dialog>

    <!-- CONFIRM DELETE -->
    <confirm-delete
      :show="showDeleteDialog"
      :deleting="$store.state.file_upload.status.deleting"
      message="Delete brand profile logo?"
      @cancel="showDeleteDialog = false"
      @confirmed="deleteConfirmed()"
    />
  </v-card>
</template>

<script>
import rules from '@/rules'
import moment from 'moment'
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    profile: Object,
    readonly: Boolean,
    description: String,
  },
  
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      moment,
      file: null,
      dialog: false,
      showDeleteDialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.brand_profile.status
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('brand_profile', [
      'uploadLogo'
    ]),

    showUploadDialog() {
      this.dialog = true
    },

    closeUploadDialog() {
      this.dialog = false
      this.file = null
      this.$refs.form.resetValidation()
    },

    validateForm() {
      if (this.$refs.form.validate()) {
        Promise.resolve(this.uploadLogo({
          profile: this.profile,
          file: this.file
        }))
        .then(() => {
          this.closeUploadDialog()
        })
      }
    },

    confirmDelete() {
      this.showDeleteDialog = true
    },

    deleteConfirmed() {
      Promise.resolve(this.$store.dispatch('file_upload/deleteFile', { folder: 'brand_profiles', file: this.profile.logo.file }))
      .then(() => {
        this.profile.ref
        .update({ logo: null })
        .then(() => {
          this.showDeleteDialog = false
          this.$store.commit('brand_profile/updateProfileData', { profile: this.profile, logo: null})
        })
        .catch(error => {
          console.log(error.message)
        })
      })
    }
  }
}
</script>