<template>
  <v-card class="box-shadow" outlined>
    <v-card-text class="pa-7 secondary--text">
      <div class="font-weight-bold">Colours</div>
      <v-divider class="my-3"></v-divider>

      <v-sheet max-width="650">
        <p v-if="description" v-html="$options.filters.linkify(description)" class="text-pre-wrap"></p>
      </v-sheet>

      <div class="d-flex flex-wrap gap-10">
        <template v-if="profile && profile.brand_colors && profile.brand_colors.length">
          <v-card
            v-for="(color, i) in profile.brand_colors"
            :key="i"
            class="rounded-lg"
            max-width="260"
            height="130" 
            width="100%" 
            outlined
          >
            <v-menu v-if="!readonly" offset-y left>
              <template #activator="{ on }">
                <v-btn
                  class="rounded-lg rounded-tr-0 rounded-bl-0"
                  :style="{ right: '-1px', bottom: '-1px' }"
                  color="grey lighten-3"
                  v-on="on"
                  outlined 
                  absolute
                  small
                  fab
                >
                  <v-hover>
                    <v-hover v-slot="{ hover }">
                      <v-icon :color="hover ? 'primary' : 'grey lighten-2'" large>mdi-dots-horizontal</v-icon>
                    </v-hover>
                  </v-hover>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="editColor(color)">
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$copyText(color.value.hexa).then(() => $store.dispatch('showSuccess', 'Copied to clipboard'))">
                  <v-list-item-title>Copy</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="deleteColor({ profile, color })" >
                  <v-list-item-title class="error--text">Delete</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            
            <v-sheet
              height="65"
              :color="color.value.hex"
              class="rounded rounded-b-0"
            ></v-sheet>
            <div class="pa-3">
              <div class="font-weight-bold">{{ color.value.hexa }}</div>
              <div class="caption grey--text">{{ color.name }}</div>
            </div>
          </v-card>
        </template>

        <v-card 
          v-if="!readonly" 
          @click="showDialog()" 
          class="transition"
          height="130" 
          width="130" 
          outlined
          hover
        >
          <v-sheet class="fill-height d-flex flex-column align-center justify-center">
            <div class="text-center">
              <v-icon color="info" class="mr-1" small>mdi-plus-circle</v-icon>
              <span class="caption">Add a color</span>
            </div>
          </v-sheet>
        </v-card>
      </div>
    </v-card-text>

    <!-- COLOR DIALOG -->
    <v-dialog
      v-model="dialog"
      :max-width="$dialog.content"
    >
      <close-btn @click="closeDialog()" overflow />
      <v-card>
        <v-card-title>
          <h5>Add Brand Color</h5>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="data.name"
              :rules="[rules.required]"
              placeholder="Name"
              outlined
              dense
            ></v-text-field>

            <v-color-picker
              v-model="data.value"
              dot-size="25"
              mode="hexa"
              swatches-max-height="200"
              width="520"
            ></v-color-picker>

            <v-btn 
              @click="addColor()"
              :loading="status.addingColor"
              color="accent gradient" 
              block
            >Add</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import rules from '@/rules'
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    profile: Object,
    readonly: Boolean,
    description: String,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      dialog: false,
      editing: false,
      data: {
        name: null,
        value: null,
      },
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.brand_profile.status
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('brand_profile', [
      'addBrandColor',
      'deleteColor',
    ]),

    showDialog() {
      this.dialog = true
    },

    closeDialog() {
      this.editing = false
      this.dialog = false
    },

    editColor(color) {
      this.data = color
      this.editing = true
      this.showDialog()
    },

    addColor() {
      if (this.$refs.form.validate()) {
        Promise.resolve(this.addBrandColor({ profile: this.profile, data: this.data, editing: this.editing }))
        .then(() => {
          this.closeDialog()
          this.data = {}
          this.$refs.form.resetValidation()
        })
      }
    }
  }
}
</script>