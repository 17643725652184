<template>
  <v-card class="box-shadow" outlined>
    <v-card-text class="pa-7 secondary--text">
      <div class="font-weight-bold">Social Media Links</div>
      <v-divider class="my-3"></v-divider>

      <v-sheet max-width="650">
        <p v-if="description" v-html="$options.filters.linkify(description)" class="text-pre-wrap"></p>
      </v-sheet>

      <v-sheet class="mt-5" max-width="600">
        <label class="form-label">Platform Link</label>

        <v-form ref="linkForm">
          <v-fade-transition group>
            <div v-for="(link, i) in profile.socials" :key="i">
              <v-text-field
                v-model="link.url"
                :rules="[rules.url]"
                @change="validateLinks()"
                :readonly="readonly"
                outlined
                dense
              >
                <template #append>
                  <v-btn v-if="i && !readonly" @click="removeLink(i)" icon x-small>
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </div>
          </v-fade-transition>
        </v-form>

        <v-btn
          v-if="!readonly"
          @click="$store.commit('brand_profile/addSocialLink', profile)"
          class="primary--text"
          color="white"
          small
        >Add Another Link</v-btn>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import rules from '@/rules'
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  props: {
    profile: Object,
    readonly: Boolean,
    description: String,
  },
  
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      moment,
      files: null,
      dialog: false,
      toDelete: null,
      showDeleteDialog: false,
      deleteMessage: null,
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('brand_profile', [
      'saveSocialLinks'
    ]),

    validateLinks() {
      if (this.$refs.linkForm.validate()) {
        this.saveSocialLinks(this.profile)
      }
    },

    removeLink(i) {
      this.$store.commit('brand_profile/removeSocialLink', { profile: this.profile, index: i })

      setTimeout(() => {
        this.validateLinks()
      }, 1000)
    }
  }
}
</script>