<template>
  <v-card v-if="profile" class="box-shadow" outlined>
    <v-card-text class="secondary--text pa-7">
      <div class="font-weight-bold">Company Information</div>
      <v-divider class="my-3"></v-divider>

      <div class="d-flex">
        <v-sheet max-width="650">
          <v-form ref="form">
            <v-row dense>
              <v-col cols="6">
                <label class="form-label">Company Name</label>
                <v-text-field
                  v-model="profile.name"
                  :rules="[rules.required]"
                  :readonly="readonly"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <label class="form-label">Company Website</label>
                <v-text-field
                  v-model="profile.company_website"
                  :rules="[rules.url]"
                  :readonly="readonly"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <label class="form-label">Email Address</label>
                <v-text-field
                  v-model="profile.email"
                  :rules="[rules.email]"
                  :readonly="readonly"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <label class="form-label">Phone Number</label>
                <v-text-field
                  v-model="profile.phone"
                  :readonly="readonly"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              
              <!-- <v-col cols="12">
                <label class="form-label">Locations</label>
                <v-fade-transition group>
                  <div v-for="(location, i) in profile.locations" :key="i">
                    <v-text-field
                      v-model="location.location"
                      :readonly="readonly"
                      outlined
                      dense
                    >
                      <template #append>
                        <v-btn v-if="i" @click="$store.commit('brand_profile/removeLocation', { profile, index: i })" icon x-small>
                          <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </div>
                </v-fade-transition>

                <v-btn
                  v-if="!readonly"
                  @click="$store.commit('brand_profile/addLocation', profile)"
                  class="primary--text"
                  color="white"
                  small
                >Add Another Location</v-btn>
              </v-col> -->
              
              <v-col cols="12" class="mt-3">
                <label class="form-label">Company Description</label>
                <v-textarea
                  v-model="profile.description"
                  placeholder="Enter more detailed information about your company such as it's business field etc..."
                  :readonly="readonly"
                  outlined
                  rows="3"
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <label class="form-label">Timezone</label>
                <v-combobox
                  v-model="profile.timezone"
                  :readonly="readonly"
                  :items="timezones"
                  item-value="value"
                  item-text="text"
                  outlined
                  dense
                ></v-combobox>
              </v-col>
              
              <!-- <v-col cols="12">
                <label class="form-label">What products or services do you sell?</label>
                <v-textarea
                  v-model="profile.products"
                  placeholder="Provide us details about how you generate revenue in your business."
                  :readonly="readonly"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col> -->
              
              <v-col cols="12">
                <label class="form-label">Who are your ideal students?</label>
                <v-textarea
                  v-model="profile.ideal_customers"
                  placeholder="Describe in detail who you sell your products and services to."
                  :readonly="readonly"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              
              <!-- <v-col cols="12">
                <label class="form-label">What are the biggest problems you solve for your customers?</label>
                <v-textarea
                  v-model="profile.problems"
                  placeholder="Tell us here…."
                  :readonly="readonly"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              
              <v-col cols="12">
                <label class="form-label">What are the steps new customers take to purchase from you?</label>
                <v-textarea
                  v-model="profile.steps"
                  placeholder="Do they book a strategy call, request a quote, reach out through a contact form, book an application phone call etc."
                  :readonly="readonly"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              
              <v-col cols="12">
                <label class="form-label">What are the six biggest reasons your customers choose you above your competitors?</label>
                <v-textarea
                  v-model="profile.reasons"
                  placeholder="Describe your unique selling points (USPs)"
                  :readonly="readonly"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              
              <v-col cols="12">
                <label class="form-label">What are the overall goals for your website?</label>
                <v-textarea
                  v-model="profile.goals"
                  placeholder="Let us know so we can help you achieve them!"
                  :readonly="readonly"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              
              <v-col cols="12">
                <label class="form-label">Provide some websites you like</label>
                <v-fade-transition group>
                  <div v-for="(website, i) in profile.websites" :key="i">
                    <v-text-field
                      v-model="website.url"
                      :readonly="readonly"
                      :rules="[rules.url]"
                      outlined
                      dense
                    >
                      <template #append>
                        <v-btn v-if="i" @click="$store.commit('brand_profile/removeUrl', { profile, index: i })" icon x-small>
                          <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </div>
                </v-fade-transition>

                <v-btn
                  v-if="!readonly"
                  @click="$store.commit('brand_profile/addUrl', profile)"
                  class="primary--text"
                  color="white"
                  small
                >Add Another Url</v-btn>
              </v-col>
              
              <v-col cols="12">
                <label class="form-label">What keywords do you want to rank on the search engines for?</label>
                <v-combobox
                  v-model="profile.keywords"
                  :readonly="readonly"
                  deletable-chips
                  small-chips
                  multiple
                  outlined
                  chips
                ></v-combobox>
              </v-col>
              
              <v-col cols="12">
                <label class="form-label">Anything else you want us to know?</label>
                <v-textarea
                  v-model="profile.anything_else"
                  placeholder="Provide us with any other information you think will help us build you an amazing website"
                  :readonly="readonly"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col> -->
            </v-row>
          </v-form>
        </v-sheet>

        <v-sheet v-if="$route.name === 'EditBrandProfile' || $store.getters['user/isEmployee']" class="ml-auto" width="450">
          <InviteMembers :profile="profile" />
        </v-sheet>
      </div>

      <v-btn 
        v-if="!readonly"
        @click="validateForm()"
        :loading="status.creating"
        color="primary"
        small
      >Update Details</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import rules from '@/rules'
import { mapState, mapActions } from 'vuex'
import timezones from '@/json/timezones.json'
import InviteMembers from './InviteMembers.vue'

export default {
  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    profile: Object,
    readonly: Boolean,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      timezones,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.brand_profile.status
    })
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    InviteMembers,
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('brand_profile', [
      'createProfile'
    ]),

    validateForm() {
      if (this.$refs.form.validate()) {
        this.$store.commit('brand_profile/setData', this.profile)
        this.createProfile()
      }
    }
  }
}
</script>